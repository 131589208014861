import React from 'react'
import styled from 'styled-components';
import { SplitColumn } from '../split-column';
import { colors } from '../../style/colors';
import CoverImage from '../cover-image';
import { MainButton } from '../../style/buttons';
import { SectionTitle } from '../../style/typography';
import { Link } from 'gatsby';

const Content = styled.div`
    color: white;
    z-index: 1;
    padding: 15% 10%;

    min-height: 100vh;
`;
const Copy = styled.p`
    max-width: 500px;
    font-size: 16px;
`;
const Cover = styled.div`
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: black;
    max-height: 100vh;
`;
const Image = styled.img`
    margin: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
`;

const HomeAbout = ({ data }) => {

    const { acf: home } = data

    return (
        <SplitColumn>
            <Cover>
                <Image src={require('../../images/black-building.jpg')} />
            </Cover> 
            <Content>
                <SectionTitle>{home.about_title}</SectionTitle>
                <Copy>{home.about_copy}</Copy>
                <Link to="/about"><MainButton>About Us</MainButton></Link>
            </Content>
        </SplitColumn>
    )
}

export default HomeAbout