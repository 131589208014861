import React from 'react'
import styled from 'styled-components';
import { SplitColumn } from '../split-column';
import { colors } from '../../style/colors';
import CoverImage from '../cover-image';
import { MainButton } from '../../style/buttons';
import { SectionTitle } from '../../style/typography';
import { Link } from 'gatsby';
import ReactPlayer from 'react-player';

const Content = styled.div`
  background: ${colors.main};
  color: white;
  padding: 15% 10%;
`;
const Copy = styled.p`
    max-width: 500px;
    font-size: 16px;
`;
const VideoContainer = styled.div`
    width: 100%;
    background: ${colors.main};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 70px 20px;
    position: relative;
    height: 100%;

    @media (max-width: 550px) {
        padding: 20px;
    }
`;
const Image = styled.img`
    margin: 0;
`;

const HomeCommitment = ({ data }) => {

    const { acf: home } = data

    return (
        <SplitColumn>
            <Content>
                <SectionTitle>{home.team_title}</SectionTitle>
                <Copy>{home.team_copy}</Copy>
                <Link to="/team"><MainButton>The Team</MainButton></Link>
            </Content>
            <VideoContainer>

                <ReactPlayer 
                    url={home.team_video_url}
                    width='100%'
                    height='100%'
                />
            </VideoContainer> 
        </SplitColumn>
    )
}

export default HomeCommitment