import React from 'react'
import styled from 'styled-components';
import { colors } from '../../style/colors';

const Container = styled.div`
    width: 100%;
    /* padding: 15px; */
    /* background: ${colors.light}; */
    margin: 10px 0;
    /* height: 120px; */

    @media (max-width: 700px) {
        margin: 20px 10px;
    }
`;
const Icon = styled.img`
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: contain;
`;

const ServiceItem = ({ icon }) => {

    return (
        <Container>
            <Icon src={icon} />
        </Container>
    )
}

export default ServiceItem