import React from 'react'
import styled from 'styled-components';
import { SplitColumn } from '../split-column';
import { colors } from '../../style/colors';
import CoverImage from '../cover-image';
import { SectionTitle } from '../../style/typography';
import Img from "gatsby-image"
import { Link } from 'gatsby';

const Content = styled.div`
  background: ${colors.main};
  color: white;
  padding: 15% 10%;
`;

const Highlight = styled.span`
    border-bottom: 2px solid #9DCFCA;

    a {
        color: inherit;
        text-decoration: none;
    }
`;
const ImageContainer = styled.div`
    position: relative;
    max-height: 90vh;
    overflow: hidden;
`;
const Copy = styled.p`
    max-width: 500px;
    font-size: 16px;
`;

const HomeIntro = ({ data }) => {

    return (
        <SplitColumn>
            <Content>
                <SectionTitle>Always <Highlight><Link to="/about">consistent</Link></Highlight>, Beston’s focus on building <Highlight><Link to="/projects">world-class results</Link></Highlight> – whether in skills, methodologies, or people – leads to our delivery of <Highlight><Link to="/about
                ">excellence</Link></Highlight>.</SectionTitle>
            </Content>
            <ImageContainer>
                <Img
                    style={{
                    width: "100%",
                    height: "100%",
                    margin: 0,
                    }}
                    fadeIn
                    objectFit="cover"
                    fluid={data.acf.intro_image.localFile.childImageSharp.fluid}
                />

            </ImageContainer>
            {/* <CoverImage image={require('../../images/intro.png')} />  */}
        </SplitColumn>
    )
}

export default HomeIntro