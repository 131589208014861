import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeIntro from "../components/home/intro";
import HomeServices from "../components/home/services";
import HomeCommitment from "../components/home/home-commitment";
import HomeAbout from "../components/home/home-about";


const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <HomeIntro data={data.home} />
    <HomeServices data={data.home}  />
    <HomeCommitment data={data.home}  />
    <HomeAbout data={data.home}  />
  </Layout>
)

export default IndexPage
export const IndexQuery = graphql`
  query HomeQuery {
    home: wordpressPage(title: {eq: "Home Web"}) {
      title
      acf {
        about_copy
        about_title
        services
        team_copy
        team_title
        team_video_url
        intro_image {
          source_url
          localFile {
            childImageSharp {
              fluid(maxHeight: 1500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }

`