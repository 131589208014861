import React from 'react'
import styled from 'styled-components';
import { MainButton } from '../../style/buttons';
import ServiceItem from './service-item';
import { Link } from 'gatsby';

const Grid = styled.section`
    display: grid;
    grid-template-columns: auto 60% auto;
    padding: 90px 20px;
    
    @media (max-width: 700px) {
        grid-template-columns: 100%;
    }
  
`;
const ServiceColumn = styled.div` 
    display: flex;
    flex-direction: column;

    @media (max-width: 700px) {
        flex-direction: row;
    }
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10%;
    text-align: center;

    @media (max-width: 700px) {
        padding: 30px;
    }

`;
const Title = styled.h2`
    text-transform: uppercase;
`;
const Copy = styled.div`
  
`;

const HomeServices = ({ data }) => {
    return (
        <Grid>
            <ServiceColumn>
                <ServiceItem icon={require('../../images/icons/1.svg')} />
                <ServiceItem icon={require('../../images/icons/1.svg')} />
                <ServiceItem icon={require('../../images/icons/1.svg')} />
            </ServiceColumn>
            <Content>
                <Title>Services</Title>
                <Copy dangerouslySetInnerHTML={{__html: data.acf.services}} />
                <Link to="/services"><MainButton>Learn More</MainButton></Link>
            </Content>
            <ServiceColumn>
                <ServiceItem icon={require('../../images/icons/4.svg')} />
                <ServiceItem icon={require('../../images/icons/5.svg')} />
                <ServiceItem icon={require('../../images/icons/6.svg')} />
            </ServiceColumn>
        </Grid>
    )
}

export default HomeServices